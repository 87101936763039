<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12" md="6" class="flex-items">
        <v-tabs v-model="activeTab" color="#519043" align-tabs="left">
          <v-tab v-for="tab in tabList" :key="tab.id" @click="changeTab(tab)">{{
            tab.name
          }}</v-tab>
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" class="d-flex justify-space-between">
        <v-text-field
          v-model="search"
          outlined
          prepend-inner-icon="search"
          label="Search"
          single-line
          hide-details
          class="rounded-lg"
          color="#239FAB"
          dense
        ></v-text-field>
        <v-btn
          class="white--text ml-2 rounded-lg"
          color="#519043"
          v-if="tab == 1"
          @click="add()"
        >
          <v-icon left> mdi-plus-box-outline </v-icon>
          Add New
        </v-btn>
        <v-btn
          :class="tab == 3 ? '' : 'd-none'"
          class="white--text ml-2 rounded-lg"
          color="#519043"
          v-if="this.$store.state.user.user.isAdminApproved == 1"
          @click="printJobApplicants()"
        >
          <v-icon left> mdi-printer-outline </v-icon>
          Print
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="ma-5 dt-container" elevation="0" outlined>
      <v-data-table
        :headers="tab == 2 ? headers2 : headers"
        :items="data"
        :items-per-page="10"
        :search="search"
        :options.sync="options"
        :loading="loading"
        @pagination="pagination"
        hide-default-footer
      >
        <template v-slot:[`item.effective_date`]="{ item }">
          {{ formatDate(item.effective_date) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="text-no-wrap">
            <v-btn
              x-small
              color="orange"
              class="mx-1"
              v-if="tab == 1"
              outlined
              @click="tag(item)"
            >
              <v-icon size="14">mdi-tag-multiple-outline</v-icon>Tag
            </v-btn>
            <v-btn
              x-small
              color="blue"
              class="mx-1"
              outlined
              @click="viewItem(item)"
            >
              <v-icon size="14">mdi-eye-outline</v-icon>View
            </v-btn>

            <v-btn
              x-small
              color="green"
              class="mx-1"
              outlined
              v-if="tab == 1"
              @click="editItem(item)"
            >
              <v-icon size="14">mdi-pencil-outline</v-icon>Update
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-row class="mb-2 mx-5" align="center">
      <v-col cols="auto" class="mr-auto text-truncate flex-items" no-gutters>
        <span class="px-2">Show</span>
        <span>
          <v-select
            dense
            outlined
            color="#519043"
            hide-details
            :value="options.itemsPerPage"
            style="max-width: 90px"
            class="rounded-lg"
            @change="options.itemsPerPage = parseInt($event, 10)"
            :items="perPageChoices"
          >
          </v-select>
        </span>
        <span class="px-2"> Entries </span>
      </v-col>

      <v-col cols="auto" class="mr-auto text-truncate" no-gutters>
        Showing {{ paginationData.pageStart + 1 }} to
        {{ paginationData.pageStop }} of
        {{ paginationData.itemsLength }} entries
      </v-col>
      <v-col cols="auto">
        <v-pagination
          v-model="options.page"
          class="rounded-lg"
          :total-visible="7"
          color="#519043"
          :length="paginationData.pageCount"
        >
        </v-pagination>
      </v-col>
    </v-row>

    <TagResolutionDialog :data="itemData" :action="action" />
    <TagResolutionToEmployeeDialog :data="taggingData" :action="action" />

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
      :timeout="5000"
    ></fade-away-message-component>
  </div>
</template>
<script>
export default {
  components: {
    // CoreTimeDesignationDialog: () =>
    //   import("../../components/Dialogs/Forms/CoreTimeDesignationDialog.vue"),
    TagResolutionDialog: () =>
      import("../../components/Dialogs/Forms/TagResolutionDialog.vue"),
    TagResolutionToEmployeeDialog: () =>
      import(
        "../../components/Dialogs/Forms/TagResolutionToEmployeeDialog.vue"
      ),
  },
  data: () => ({
    search: "",
    fullname: null,
    applicantData: null,
    itemData: null,
    taggingData: null,
    headers: [
      {
        text: "Resolution Number",
        value: "resolution",
        align: "start",
        valign: "center",
        sortable: false,
      },
      {
        text: "Effective Data",
        value: "effective_date",
        align: "center",
        valign: "center",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        align: "end",
        valign: "center",
        sortable: false,
      },
    ],
    headers2: [
      {
        text: "Resolution Number",
        value: "resolution",
        align: "start",
        valign: "center",
        sortable: false,
      },
      {
        text: "Employee",
        value: "name",
        align: "center",
        valign: "center",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        align: "end",
        valign: "center",
        sortable: false,
      },
    ],

    data: [],

    perPageChoices: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "250", value: 250 },
      { text: "500", value: 500 },
    ],
    activeTab: { id: 1, name: "Active-Jobs" },
    tab: 1,
    tabList: [
      { id: 1, name: "Resolutions" },
      { id: 2, name: "Tagged Employees" },
      //   { id: 3, name: "Applicants" },
    ],

    loading: false,
    options: {},
    action: null,
    paginationData: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Deleted!",
      message: "",
      top: 10,
    },
    yearList: [],
    monthsList: [
      { id: 0, name: "All" },
      { id: 1, name: "January" },
      { id: 2, name: "February" },
      { id: 3, name: "March" },
      { id: 4, name: "April" },
      { id: 5, name: "May" },
      { id: 6, name: "June" },
      { id: 7, name: "July" },
      { id: 8, name: "August" },
      { id: 9, name: "September" },
      { id: 10, name: "October" },
      { id: 11, name: "November" },
      { id: 12, name: "December" },
    ],
  }),

  mounted() {
    this.eventHub.$on("closeResolutionDialog", () => {
      this.initialize();
    });
  },

  beforeDestroy() {
    this.eventHub.$off("closeResolutionDialog");
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    filterYear: {
      handler(newData, oldData) {
        if (oldData != newData) {
          this.initialize();
        }
      },
      deep: true,
    },
  },
  computed: {
    filterYear() {
      return this.$store.getters.getFilterSelected;
    },
  },
  methods: {
    pagination(data) {
      this.paginationData = data;
    },

    initialize() {
      // this.handleAllChanges();
      if (this.tab == 1) {
        this.loading = true;
        //   let filter = this.$store.getters.getFilterSelected;
        //   console.log("Filted", filter);
        this.axiosCall("/resolution", "GET").then((res) => {
          if (res) {
            //   console.log("Love", res.data);
            this.data = res.data;
            this.loading = false;
          }
        });
      } else {
        this.loading = true;
        //   let filter = this.$store.getters.getFilterSelected;
        //   console.log("Filted", filter);
        this.axiosCall("/resolution-tagged", "GET").then((res) => {
          if (res) {
            //   console.log("Love", res.data);
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
              data[i].name = this.toTitleCase(data[i].name);
            }
            this.data = data;
            this.loading = false;
          }
        });
      }
    },

    changeTab(tab) {
      this.activeTab = tab;

      // if (tab.id == 1) {

      this.tab = tab.id;
      this.initialize();
      // } else if (tab.id == 2) {
      //   this.getVerifiedUsers();
      //   this.tab = tab.id;
      // }
    },
    add() {
      this.itemData = [{ id: null }];
      this.action = "Add";
    },

    tag(item) {
      this.taggingData = item;
      this.action = "Tag";
    },

    editItem(item) {
      this.itemData = item;
      this.action = "Update";
    },

    viewItem(item) {
      console.log(item.filename);
      let url =
        process.env.VUE_APP_SERVER + "/resolution/viewfile/" + item.filename;

      let token = localStorage.getItem("token");
      const tab = window.open("about:blank");
      this.isLoading = true;
      fetch(url, { headers: { Authorization: `Bearer ${token}` } }) // FETCH BLOB FROM IT
        .then((response) => response.blob())
        .then((blob) => {
          // RETRIEVE THE BLOB AND CREATE LOCAL URL
          var _url = window.URL.createObjectURL(blob);

          tab.location = _url;
          tab.focus();
          // window.open(_url, "_blank").focus();
          this.isLoading = false; // window.open + focus
        })
        .catch((err) => {
          console.log(err);
        });
      //   this.itemData = item;
      //   this.action = "View";
    },
  },
};
</script>
